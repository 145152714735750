import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/search',
    name: 'search',
    component: () => import('@/pages/search.vue')
  },
  {
    path: '/waiting-passengers',
    name: 'waiting-passengers',
    component: () => import('@/pages/waiting_passengers.vue')
  },
  {
    path: '/subscription-new',
    name: 'subscription-new',
    component: () => import('@/pages/subscription_new.vue')
  },
  {
    path: '/trip-new',
    name: 'trip-new',
    component: () => import('@/pages/trip_new.vue')
  },
  {
    path: '/trips',
    name: 'my-trips',
    component: () => import('@/pages/my_trips.vue')
  },
  {
    path: '/trips/:id',
    name: 'trip-detail',
    component: () => import('@/pages/trip_detail.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('@/pages/info.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/pages/settings.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
